<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main">{{$t('sycm.common.statisticalTime')}} {{ parseInt(Date.now()/1000) | parseTime()}}</div>
            <ul class="filter-bar">
                <li class="bar-item"><el-button type="primary" size="mini">{{$t('sycm.actions.realTime')}}</el-button></li>
                <li class="bar-item">
                    <el-dropdown class="sycm-bar-dropdown" placement="bottom" @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{ terminalType | terminalFilter($t('sycm.dropdown.terminal')) }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,index) in $t('sycm.dropdown.terminal')" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
            </ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.ipoll.overview')}}</div>
				<div class="barbox">
					<span>{{$t('sycm.ipoll.refreshTips')}}</span>
					<el-button type="primary" size="small" @click="getIpollOverview" plain><i class="el-icon-refresh-right"></i> {{$t('sycm.actions.refresh')}}</el-button>
				</div>
			</div>
			<div class="section-main">
				<div class="sycm-panel">
					<div class="item" v-if="overviewData.visitors">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.common.visitor')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover"
									popper-class="sycm-popover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-head">
										<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.visitor')+'”'}}</p>
										<p>{{$t('sycm.popover.visitor1')}}</p>
									</div>
									<div class="popover-main">
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.range')}}</div>
											<div class="item-block">{{$t('sycm.popover.visitor2')}}</div>
										</div>
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.repeat')}}</div>
											<div class="item-block">{{$t('sycm.popover.visitor3')}}</div>
										</div>
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.wave')}}</div>
											<div class="item-block">{{$t('sycm.popover.visitor4')}}</div>
										</div>
									</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.visitors.today_num}}</div>
						</div>
						<ul class="item-box">
							<li><span>{{$t('sycm.common.yesterday')}}</span><span>{{overviewData.visitors.yesterday_num}}</span></li>
							<li><span>{{$t('sycm.ipoll.mobileMix')}}</span><span>{{overviewData.visitors.mobile_percent}}%</span></li>
						</ul>
					</div>
					<div class="item" v-if="overviewData.views">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.common.views')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover"
									popper-class="sycm-popover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-head">
										<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.views')+'”'}}</p>
										<p>{{$t('sycm.popover.views1')}}</p>
									</div>
									<div class="popover-main">
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.range')}}</div>
											<div class="item-block">{{$t('sycm.popover.views2')}}</div>
										</div>
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.repeat')}}</div>
											<div class="item-block">{{$t('sycm.popover.views3')}}</div>
										</div>
									</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.views.today_num}}</div>
						</div>
						<ul class="item-box">
							<li><span>{{$t('sycm.common.yesterday')}}</span><span>{{overviewData.views.yesterday_num}}</span></li>
							<li><span>{{$t('sycm.ipoll.mobileMix')}}</span><span>{{overviewData.views.mobile_percent}}%</span></li>
						</ul>
					</div>
					<div class="item" v-if="overviewData.pay_price">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.common.payMoney')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover"
									popper-class="sycm-popover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-head">
										<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.payMoney')+'”'}}</p>
										<p>{{$t('sycm.popover.payMoney1')}}</p>
									</div>
									<div class="popover-main">
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.range')}}</div>
											<div class="item-block">{{$t('sycm.popover.payMoney2')}}</div>
										</div>
									</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.pay_price.today_num}}</div>
						</div>
						<ul class="item-box">
							<li><span>{{$t('sycm.common.yesterday')}}</span><span>{{overviewData.pay_price.yesterday_num}}</span></li>
							<li><span>{{$t('sycm.ipoll.mobileMix')}}</span><span>{{overviewData.pay_price.mobile_percent}}%</span></li>
						</ul>
					</div>
					<div class="item" v-if="overviewData.sub_order">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.common.paySubOrder')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover"
									popper-class="sycm-popover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-head">
										<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.paySubOrder')+'”'}}</p>
										<p>{{$t('sycm.popover.paySubOrder1')}}</p>
									</div>
									<div class="popover-main">
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.defineSubOrder')}}</div>
											<div class="item-block">{{$t('sycm.popover.paySubOrder2')}}</div>
										</div>
									</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.sub_order.today_num}}</div>
						</div>
						<ul class="item-box">
							<li><span>{{$t('sycm.common.yesterday')}}</span><span>{{overviewData.sub_order.yesterday_num}}</span></li>
							<li><span>{{$t('sycm.ipoll.mobileMix')}}</span><span>{{overviewData.sub_order.mobile_percent}}%</span></li>
						</ul>
					</div>
					<div class="item" v-if="overviewData.buyers">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.common.payBuyers')}}</span>
							</div>
							<div class="number">{{overviewData.buyers.today_num}}</div>
						</div>
						<ul class="item-box">
							<li><span>{{$t('sycm.common.yesterday')}}</span><span>{{overviewData.buyers.yesterday_num}}</span></li>
							<li><span>{{$t('sycm.ipoll.mobileMix')}}</span><span>{{overviewData.buyers.mobile_percent}}%</span></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.ipoll.trend')}}</div>
				<div class="barbox">
					<span>{{$t('sycm.common.contrastDay')}}</span>
					<el-date-picker
						v-model="datetimes"
						type="date"
						size="small"
						value-format="yyyy-MM-dd"
						:clearable="false"
						:placeholder="$t('sycm.placeholder.date')"
						:picker-options="pickerOptions"
						@change="getIpollTrend">
					</el-date-picker>
				</div>
			</div>
			<div class="section-main">
				<div class="sycm-chart">
					<div class="item"><chart id="item1" :title="$t('sycm.common.payMoney')" v-if="payPriceData" :x-data="payPriceData.xData" :s-data="payPriceData.sData" :max-axis="payPriceData.max.toString()" legend-right="3%" :x-interval="3" height="100%" width="100%" /></div>
					<div class="item"><chart id="item2" :title="$t('sycm.common.visitor')" v-if="visitorData" :x-data="visitorData.xData" :s-data="visitorData.sData" :max-axis="visitorData.max.toString()" legend-right="3%" :x-interval="3" height="100%" width="100%" /></div>
					<div class="item"><chart id="item3" :title="$t('sycm.common.paySubOrder')" v-if="paySubOrderData" :x-data="paySubOrderData.xData" :s-data="paySubOrderData.sData" :max-axis="paySubOrderData.max.toString()" legend-right="3%" :x-interval="3" height="100%" width="100%" /></div>
					<div class="item"><chart id="item4" :title="$t('sycm.common.payBuyers')" v-if="payBuyersData" :x-data="payBuyersData.xData" :s-data="payBuyersData.sData" :max-axis="payBuyersData.max.toString()" legend-right="3%" :x-interval="3" height="100%" width="100%" /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Chart from '@/components/echart/ipollChart'
import { parseTime } from "@/utils"
import { fetchIpollOverview, fetchIpollTrend } from "@/api/sycm"
export default {
	components: { Chart },
	filters: {
		terminalFilter(type, options){
			if(type=='') {
				type = 0
			}
			return options[type].label
		}
	},
	data() {
		return {
			terminalType: '',
			overviewData: {},
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime()+86400000 > Date.now() || time.getTime() < today.getTime() - 86400000;
				},
				firstDayOfWeek: 1
			},
			datetimes: parseTime(Date.now() - 86400000,'{y}-{m}-{d}'),
			payPriceData: null,
			visitorData: null,
			paySubOrderData: null,
			payBuyersData: null
		}
	},
	created () {
		this.getData()
	},
	methods: {
		async getData() {
			const loading = this.$loading()
			this.getIpollOverview()
			this.getIpollTrend()
			loading.close()
		},
		getIpollOverview() {
			const params = {
				uuid: this.$store.getters.uuid,
				source: this.terminalType
			}
			fetchIpollOverview(params).then(response => {
				const { data } = response
				this.overviewData = data
			})
		},
		getIpollTrend() {
			this.payPriceData = null
			this.visitorData = null
			this.paySubOrderData = null
			this.payBuyersData = null
			const params = {
				date: this.datetimes,
				source: this.terminalType
			}
			fetchIpollTrend(params).then(response => {
				const { data } = response
				this.payPriceData = this.getChartData(data.pay_price)
				this.visitorData = this.getChartData(data.visitors)
				this.paySubOrderData = this.getChartData(data.sub_orders)
				this.payBuyersData = this.getChartData(data.buyers)
			})
		},
		getChartData(data) {
			const xData = []
			const todayData = []
			const yesterdayData = []
			var max = 0
			data.today_num.forEach(item=> {
				xData.push(item.x_values+':00')
				todayData.push(item.values)
				if (item.values > max) {
					max = item.values
				}
			})
			data.yesterday_num.forEach(item=> {
				yesterdayData.push(item.values)
				if (item.values > max) {
					max = item.values
				}
			})
			const option = {
				max: max ? max : 4,
				xData: xData,
				sData: [{
					name: this.$t('sycm.common.today'),
					data: todayData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					itemStyle: {
						color: '#2062e6'
					}
				}, {
					name: this.$t('sycm.common.contrastDay'),
					data: yesterdayData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					itemStyle: {
						color: '#f3d024'
					}
				}]
			}
			return option
		},
		handleCommand(command) {
			this.terminalType = command
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;
		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;
			.title {
				font-size: 16px;
				font-weight: bold;
			}
			.barbox {
				span {
					margin-right: 10px;
				}
			}
		}
		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}
	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;
		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}
		.filter-bar {
			display: flex;
			.bar-item {
				margin-left: 10px;
			}
		}
	}
	.sycm-panel {
		display: flex;
		padding: 20px 0;
		.item {
			width: 20%;
			flex-shrink: 1;
			padding: 18px 10px 18px 20px;
			line-height: normal;
			border: 1px solid #e6e6e6;
			border-left: none;
			&:first-child {
				border-left: 1px solid #e6e6e6;
			}
			.item-main {
				.name {
					font-size: 14px;
					.el-popover__reference {
						color: #999999;
						margin-left: 4px;
						cursor: pointer;
					}
				}
				.number {
					font-size: 24px;
					font-weight: 700;
					margin: 8px 0 7px;
				}
			}
			.item-box {
				li {
					color: #999999;
					font-size: 12px;
					display: flex;
					justify-content: space-between;
					padding-right: 24px;
				}
			}
		}
	}
	.sycm-chart {
		overflow: hidden;
		padding: 20px 0;
		.item {
			float: left;
			width: 49%;
			height: 316px;
			margin-right: 2%;
			padding-bottom: 30px;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}
</style>